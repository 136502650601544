<template>
  <v-app>
    <router-view />
    <!-- <notification-popup :notification-duration="300000" /> -->
    <toast ref="Toast" />
    <loader ref="Loader" />
  </v-app>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { setFavicon } from '@/utils/utils';
import { getStaticAssetUrl } from '@/utils/files';
import { SIGN_OUT } from './store/user/types.js';
import Toast from './components/Toast.vue';
import Loader from './components/Loader.vue';

export default {
  name: 'App',
  components: {
    Toast,
    Loader,
  },
  data() {
    return {
      isInitialRouteLoadComplete: false
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      siteBranding: (state) => state.branding.siteBranding
      // notifications_events: (state) => state.notifications.notifications_events
    })
  },
  beforeCreate() {
    // This will be overwritten dynamically, but needs an initial value to prevent URL from showing as document title
    document.title = 'Davra Showrooms';
  },
  beforeMount() {
    this.initActivityTracker();
  },
  mounted() {
    this.$root.Toast = this.$refs.Toast;
    this.$root.Loader = this.$refs.Loader;
    this.$router.afterEach(() => {
      // afterEach fires each time the route changes, use this flag to determine the first time the app routes load
      if (!this.isInitialRouteLoadComplete) {
        // perform one-time setup here
        this.isInitialRouteLoadComplete = true;
        if (this.siteBranding && this.siteBranding.faviconImageFileId) {
          setFavicon(getStaticAssetUrl(this.siteBranding.faviconImageFileId));
        }
      }
    });
    // setTimeout(() => this.getNotificationsEvents(), 3000);
  },
  methods: {
    ...mapActions({
      onProfileSignOut: SIGN_OUT,
      initActivityTracker: 'initActivityTracker'
      // getNotificationsEvents: GET_NOTIFICATIONS_EVENTS
    }),
    // ...mapMutations({
    // setNotificationsEvent: SET_NOTIFICATIONS_EVENT
    // }),
    /*notify(messageData) {
      // console.log('notification received', messageData);
      const { title, subtitle, link, createdTime } = messageData;
      const text = `${subtitle}|${link}~${moment(createdTime).fromNow()}`;
      this.$notify({
        group: 'event_upcoming',
        title: title,
        text
      });
    },*/
  },
  // created() {
  //   this.$options.sockets.onmessage = (data) => {
  //     const parsedData = JSON.parse(data.data);
  //     console.log('socket message', parsedData);
  //     if (parsedData.type === 'EVENT') {
  //       this.setNotificationsEvent(parsedData);
  //       this.notify(parsedData);
  //     }
  //     if (parsedData.type === 'HEARTBEAT_FROM_SERVER') {
  //       console.log('HEARTBEAT_FROM_SERVER', parsedData.serverTime);
  //     }
  //   };
  // }
};
</script>
<style lang="scss">
@import './scss/variables.scss';
@import './scss/uploadcare.scss';

:root {
  --plyr-color-main: #FF0000;
  --video-list-hover: #1e90ff;
}

html {
  overflow-y: initial !important;
}

#app {
  font-family: Roboto, sans-serif;
}

.v-application {
  overflow: hidden;
}

// TODO: move vuetify overrides to another file and import here instead.

// global: x blue-grey lighten-5, chaging to below
// should really use brand background on v-main__wrap but its height is collapsed
.v-application--wrap {
  background: var(--primary-custom-lighten-2) !important;
  // border-color: #eceff1 !important;
}

.theme--light.v-footer {
  background-color: var(--primary-custom-lighten-8) !important;
}
.custom-v-sheet, .custom-v-card, .custom-v-card-non-interactable {
  border-radius: 4px !important;
  box-shadow: 0 12px 50px 2px var(--primary-custom-lighten-8) !important;
}
.custom-v-card-non-interactable {
  border-radius: 4px !important;
  box-shadow: 0 12px 50px 2px var(--primary-custom-lighten-8) !important;
}
.custom-v-card {
  &:hover {
    box-shadow: 0 12px 50px 2px var(--primary-custom-lighten-24) !important;
  }
}
.custom-shadow {
  box-shadow: 0 12px 50px 2px var(--primary-custom-lighten-8) !important;
}
.v-footer.v-sheet {
  box-shadow: none !important;
}

.wrapper {
  max-width: 1500px;
  margin: 0 auto;
  padding: 16px 24px;
}

.v-main__wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-card__title {
  word-break: break-word !important;
}

.side-menu {
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
  min-height: 100vh;

  & > header {
    flex-grow: 0;
  }
}

.side-footer {
  margin-top: auto;
}

.card {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px;
  }

  &-item {
    width: calc(33.333% - 24px);
    margin: 12px !important;
  }
}

.v-menu__content {
  z-index: 999 !important;
}
.v-tooltip__content {
  z-index: 1000 !important;
}

.booth-team-card {
  &.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
}
.davra-align-1 {
  display: flex;
  align-items: center;
}
.davra-align-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vue-notification-group {
  width: 400px !important;
}
.vue-notification {
  font-size: 12px;
  padding: 10px;
  margin: 0 5px 5px;
  color: grey !important;
  background: #fff !important;
  border-left: none !important;
}

.event-card-right {
  left: initial !important;
  right: 15px !important;
}

.event-card-left {
  left: 15px !important;
  right: initial !important;
}

.checked-background {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.hover:hover {
  cursor: pointer;
}
.conference-item-page ::-webkit-scrollbar {
  display: none;
}

.a-dialog {
  .a-dialog-text-container {
    color: #000000;
  }
}
.dark-background {
  background-color: rgba(0, 0, 0, 0.26);
  border-radius: .25rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat;
  font-weight: 600;
}

.text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6 {
font-family: Montserrat;
font-weight: 600;
}
</style>
