import AFRAME from 'aframe';

const init = function () {
  this.el.sceneEl.renderer.outputEncoding = AFRAME.THREE.sRGBEncoding;
  this.el.sceneEl.renderer.physicallyCorrectLights = true;
  this.el.sceneEl.renderer.setClearColor(0xcccccc);
  this.el.sceneEl.renderer.setPixelRatio(window.devicePixelRatio);

  // this is a required system and can only be added programatically via component
  this.el.sceneEl.setAttribute('gltf-model', `dracoDecoderPath: https://www.gstatic.com/draco/v1/decoders/`);
};

export default { init };
