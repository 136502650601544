import Vue from 'vue';
import { dynamicFormValuesToTemplate, unwrapDynamicFormValues } from '@/utils/forms';

const defaultConfiguratorState = Object.freeze({
  tabs: {
    items: [
      { id: 'upload', title: 'Upload new' },
      { id: 'library', title: 'Content Library' }
    ],
    activeIndex: 0,
  },
  stepper: {
    activeIndex: 2,
    activeContentType: null,
    availableSteps: [{ title: 'Choose type' }],
  },
  disabledTypes: []
});

export default {
  state: () => ({
    smartContentTypeIconMap: {
      poster: 'mdi-file-image-outline',
      link: 'mdi-web',
      video: 'mdi-file-video-outline',
      document: 'mdi-file-document-outline',
    },
    smartContentTypes: [],
    smartContentList: [],
    configuratorState: JSON.parse(JSON.stringify(defaultConfiguratorState)),
    configuratorBus: new Vue()
  }),
  getters: {
    smartContentListByType: (state) => (typeName) => {
      return state.smartContentList.filter((sc) => { return typeName.indexOf(sc.type) > -1; });
    },
    smartContentListByAvailability(state, getters) {
      if (!state.smartContentTypes || state.smartContentTypes.length === 0) { return []; }
      return getters.smartContentListByType(state.smartContentTypes.filter((sct) => {
        return getters.isSmartContentTypeAvailable(sct);
      }).map((sct) => { return sct.name; }));
    },
    isSmartContentTypeAvailable: (state) => (type) => {
      if (type.metadata && type.metadata.isDisabled) { return false; }
      if (state.configuratorState.disabledTypes.indexOf(type.name) > -1) { return false; }
      return true;
    },
    smartContentIconByType: (state) => (typeName) => {
      if (state.smartContentTypeIconMap[typeName]) { return state.smartContentTypeIconMap[typeName]; }
      return '';
    },
    isCurrentStepperStepValid(state) {
      return state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex - 1].isValid;
    }
  },
  mutations: {
    setConfiguratorState(state, value) {
      state.configuratorState = Object.assign({}, value);
    },
    setSmartContentTypes(state, value) {
      state.smartContentTypes = value;
    },
    setSmartContentList(state, value) {
      state.smartContentList = value;
    },
    pushToSmartContentList(state, value) {
      state.smartContentList.push(value);
    },
    updateSmartContentListById(state, { value, id }) {
      const targetIndex = state.smartContentList.findIndex((sc) => sc.id === id);
      if (targetIndex > -1) { state.smartContentList[targetIndex] = value; }
    },
    removeFromSmartContentList(state, value) {
      state.smartContentList = state.smartContentList.filter((sc) => sc.id !== value);
    },
    setDisabledContentTypes(state, value) {
      state.configuratorState.disabledTypes = value || [];
    },
    unsetCurrentStepperStepValue(state) {
      state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex - 1].value = null;
      state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex - 1].isValid = false;
    },
    setCurrentStepperStepValue(state, value) {
      state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex - 1].value = value;
      state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex - 1].isValid = true;
    },
    setIndexStepperStepValue(state, { index, value }) {
      state.configuratorState.stepper.availableSteps[index].value = value;
      state.configuratorState.stepper.availableSteps[index].isValid = true;
    }
  },
  actions: {
    resetConfiguratorState({ state }) {
      state.configuratorState.stepper = JSON.parse(JSON.stringify(defaultConfiguratorState.stepper));
    },
    async initConfigurator({ commit, dispatch }, { eventSlug, isShowExisting, disabledContentTypes }) {
      commit('setDisabledContentTypes', disabledContentTypes);
      await dispatch('getSmartContentTypes', ({ eventSlug }));
      if (isShowExisting) {
        await dispatch('getSmartContent', ({ eventSlug }));
      }
    },
    initNewContentStepper({ state, dispatch }, { eventSlug, contentType }) {
      state.configuratorState.stepper = JSON.parse(JSON.stringify(defaultConfiguratorState.stepper));
      if (contentType.metadata && contentType.metadata.steps) {
        contentType.metadata.steps.forEach((step) => {
          const stepData = Object.assign({ isValid: step.type !== 'file_upload', value: null }, step); 
          if (step.type === 'form') {
            stepData.form = dynamicFormValuesToTemplate(null, step.options.name, [], ['file_id']);
            stepData.bus = new Vue();
          }
          state.configuratorState.stepper.availableSteps.push(stepData);
        });
      }
      state.configuratorState.stepper.activeContentType = contentType;

      dispatch('addConfiguratorEventListener', { name: 'on-content-ready', listener: async (data) => {
        const result = await dispatch('createSmartContent', { eventSlug, contentType: contentType.name, data });
        return dispatch('triggerConfiguratorEvent', { name: 'on-configurator-create', data: result });
      }});
    },
    async advanceNewContentStepper({ state, dispatch }) {
      // 1. handle data on the current step
      const previousStep = state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex - 1];
      // simulate a click on the uploadcare file editor which generates data for previousStep.value
      if (previousStep.type === 'file_edit') { document.querySelector('.sr-uc .controls-row > lr-btn-ui[icon="done"]').click(); }
      // handle form submission
      if (previousStep.type === 'form') {
        let isFormValid = false;
        previousStep.bus.$emit('validate', (isValid) => { isFormValid = isValid; });
        await previousStep.bus.$nextTick();
        if (!isFormValid) { return; }
        previousStep.value = unwrapDynamicFormValues(previousStep.form);
      }

      // 2. prepare data on the next step
      const newStep = state.configuratorState.stepper.availableSteps[state.configuratorState.stepper.activeIndex];
      // pass file data as step value between upload and edit steps
      if (previousStep.type === 'file_upload' && newStep.type === 'file_edit') { newStep.value = JSON.parse(JSON.stringify(previousStep.value)); }

      // 3. advance the stepper UI, if not the last step
      if (state.configuratorState.stepper.activeIndex === state.configuratorState.stepper.availableSteps.length) {
        console.log('Done, try to save smart content');

        let newSmartContent = {};
        state.configuratorState.stepper.availableSteps.forEach((s) => {
          // merge form data directly
          if (s.type === 'form' && s.value) { newSmartContent = Object.assign(newSmartContent, s.value); }
          // merge file data as specified property, file edit always takes precedence if present
          if (s.type === 'file_edit') { newSmartContent[s.property] = s.value; }
          if (s.type === 'file_upload') { if (!newSmartContent[s.property]) { newSmartContent[s.property] = s.value; }}
        });
        return dispatch('triggerConfiguratorEvent', { name: 'on-content-ready', data: newSmartContent });
      }
      state.configuratorState.stepper.activeIndex++;
    },
    async createSmartContent({ state, commit }, { eventSlug, contentType, data }) {
      try {
        const response = await Vue.axios.post(`/api/${eventSlug}/smart_content`, { type: contentType, content: data });
        if (!response || !response.data) { return null; }
        commit('pushToSmartContentList', response.data);
        return response.data;
      } catch (e) {
        return null;
      }
    },
    async getSmartContentTypes({ state, commit }, { eventSlug }) {
      if (state.smartContentTypes.length > 0) { return state.smartContentTypes; }
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/smart_content/types`);
        if (!response || !response.data) { return null; }
        commit('setSmartContentTypes', response.data);
        return response.data;
      } catch (e) {
        return [];
      }
    },
    async getSmartContent({ state, commit }, { eventSlug }) {
      // if (state.smartContentList.length > 0) { return state.smartContentList; }
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/smart_content`);
        if (!response || !response.data) { return null; }
        commit('setSmartContentList', response.data);
        return response.data;
      } catch (e) {
        return [];
      }
    },
    async getSmartContentById(_context, { eventSlug, smartContentId }) {
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/smart_content/${smartContentId}`);
        if (!response || !response.data) { return null; }
        return response.data;
      } catch (e) {
        return null;
      }
    },
    async updateSmartContentById({ commit }, { eventSlug, smartContent, smartContentId }) {
      try {
        const response = await Vue.axios.put(`/api/${eventSlug}/smart_content/${smartContentId}`, smartContent);
        if (!response || !response.data) { return null; }
        commit('updateSmartContentListById', { id: smartContentId, value: response.data });
        return response.data;
      } catch (e) {
        return null;
      }
    },
    async deleteSmartContentById({ commit }, { eventSlug, smartContentId }) {
      try {
        const response = await Vue.axios.delete(`/api/${eventSlug}/smart_content/${smartContentId}`);
        if (!response || !response.data) { return null; }
        commit('removeFromSmartContentList', smartContentId);
        return response.data;
      } catch (e) {
        return [];
      }
    },
    triggerConfiguratorEvent({ state }, { name, data }) {
      state.configuratorBus.$emit(name, data);
    },
    addConfiguratorEventListener({ state }, { name, listener }) {
      state.configuratorBus.$off(name);
      state.configuratorBus.$on(name, listener);
    }
  },
};
