const schema = {
  isMicVisible: { type: 'boolean', default: true },
  isFrameVisible: { type: 'boolean', default: false },
  isUserSpeaking: { type: 'boolean', default: false},
  playerId: { type: 'string', default: '' },
  playerName: { type: 'string', default: '' },
  playerCompany: { type: 'string', default: '' },
  playerRole: { type: 'string', default: '' },
  micImageVideo: {type: 'asset'},
  likeImageVideo: {type: 'asset'},
  micImageLabel: {type: 'asset'},
  likeImageLabel: {type: 'asset'}
};

const init = function () {

  const nametagEl = this.el.querySelector('.nametag');
  if (nametagEl && this.el.id !== 'player-model-entity') {
    //If the microphone is muted
    if(this.data.isMicVisible) {
      // Create the plane for the name tag
      // Mic icon
      const micIcon = this.el.querySelector('.mic-icon-nametag')
      micIcon.setAttribute('visible', true);
      micIcon.setAttribute('src', this.data.micImageLabel);
      micIcon.setAttribute('material', {
        side: 'front',
        opacity: 1,
        transparent: true
      });

      var labelPlane = this.el.querySelector('.plane-nametag');
      labelPlane.setAttribute('geometry', {
        primitive: 'plane',
        width: 1.0,
        height: 0.25
      });

      labelPlane.setAttribute('material', {
        color: 'black',
        opacity: 1,
        transparent: false,
        side: 'front',
        shader: 'flat'
      });
      labelPlane.setAttribute('position', '0 0.1 0');

      // Name, Company, Role appended to plane
      // Name
      var userNameLabel = document.createElement('a-troika-text');
      userNameLabel.setAttribute('text', {
        color: 'white',
        width: 1.5,
        wrapCount: 30, 
        value: this.data.playerName,
        shader: 'flat'
      });
      userNameLabel.setAttribute('position', '.3 0.06 0');
      labelPlane.appendChild(userNameLabel);

      // Company - Role
      var userCompanyRole = document.createElement('a-troika-text');
      userCompanyRole.setAttribute('text', {
        color: 'white',
        width: 1.5,
        value: `${this.data.playerCompany} - ${this.data.playerRole}`,
        shader: 'flat'
      });
      userCompanyRole.setAttribute('position', '.3 -0.04 0');
      labelPlane.appendChild(userCompanyRole);

      // Progress Planes - Experience, Network, Learn
      // Experience
      var progressPlaneExperience = document.createElement('a-entity');
      progressPlaneExperience.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneExperience.setAttribute('material', {
        color: '#FDFC0D',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneExperience.setAttribute('position', '-0.41 -0.115 0.01');
      labelPlane.appendChild(progressPlaneExperience);
      // Network
      var progressPlaneNetwork = document.createElement('a-entity');
      progressPlaneNetwork.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025,
      });
      progressPlaneNetwork.setAttribute('material', {
        color: '#3FA9F5',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneNetwork.setAttribute('position', '-0.235 -0.115 0.01');
      labelPlane.appendChild(progressPlaneNetwork);
      // Learn
      var progressPlaneLearn = document.createElement('a-entity');
      progressPlaneLearn.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneLearn.setAttribute('material', {
        color: '#ED1E79',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneLearn.setAttribute('position', '-0.06 -0.115 0.01');
      labelPlane.appendChild(progressPlaneLearn);

      // // Like icon
      var likeIcon = document.createElement('a-image');
      likeIcon.setAttribute('src', this.data.likeImageLabel);
      likeIcon.setAttribute('width', 0.1,);
      likeIcon.setAttribute('height', 0.1);
      likeIcon.setAttribute('position', '0.325 0.055 0.01');
      likeIcon.setAttribute('material', {
        side: 'front',
        opacity: 1,
        transparent: true,
        shader: 'flat'
      });
      labelPlane.appendChild(likeIcon);
      // Like counter
      var likeCounter = document.createElement('a-troika-text');
      likeCounter.setAttribute('text', {
        color: 'white',
        width: 1.5,
        value: 22
      });
      likeCounter.setAttribute('position', '0.805 0 0.01');
      likeIcon.appendChild(likeCounter);

      // // TODO - Logic for opening the player's profile will go here
      labelPlane.setAttribute('raycaster-interaction-hotspot', { meshLabelText: 'Profile' });
      const nametagClickedEvent = new CustomEvent('on-nametag-clicked', { detail: { playerId: this.data.playerId } });
      labelPlane.addEventListener('click', () => document.dispatchEvent(nametagClickedEvent));
    } else {
      // Hide mic icon
      const micIcon = this.el.querySelector('.mic-icon-nametag');
      micIcon.setAttribute('visible', false);
    }
    if (this.data.isUserSpeaking) {
      var labelPlane = this.el.querySelector('.plane-nametag');
      labelPlane.setAttribute('material', {
        color: 'green',
        opacity: 1,
        transparent: false,
        side: 'front',
        shader: 'flat'
      });
    }
    else {
      var labelPlane = this.el.querySelector('.plane-nametag');
      labelPlane.setAttribute('material', {
        color: 'black',
        opacity: 1,
        transparent: false,
        side: 'front',
        shader: 'flat'
      });
    }
  }

  const videoFrameEl = this.el.querySelector('.networked-video-frame');
  if (this.el.id === 'player-model-entity') { return; }
  const planeMesh = videoFrameEl.getObject3D('mesh');
  planeMesh.traverse(node => {
    if (this.data.isFrameVisible) {
      if (this.data.isMicVisible) {
        // Create the plane for the name tag
        // Mic icon
        const micIcon = this.el.querySelector('.mic-icon-video')
        micIcon.setAttribute('visible', true);
        micIcon.setAttribute('src', this.data.micImageVideo);
        micIcon.setAttribute('material', {
          side: 'front',
          opacity: 1,
          transparent: true
        });
      }
      else {
        // Create the plane for the name tag
        // Mic icon
        const micIcon = this.el.querySelector('.mic-icon-video')
        micIcon.setAttribute('visible', false);
      }
      // Hide nametag labels
      const userNameEl = this.el.querySelector('.nametag')
      userNameEl.setAttribute('visible', false);

      // Show video labels
      const videoFrameEl = this.el.querySelector('.networked-video-frame');
      videoFrameEl.setAttribute('visible', true);

      node.material.transparent = false;
      node.material.opacity = 1;
      
      var labelPlane = document.createElement('a-entity');
      labelPlane.setAttribute('geometry', {
        primitive: 'plane',
        width: 1.0,
        height: 0.5
      });
      labelPlane.setAttribute('material', {
        color: 'black',
        opacity: 0.0,
        transparent: true,
        shader: 'flat'
      });
      labelPlane.setAttribute('position', '0 0.1 0');

      // Name, Company, Role appended to plane
      // Name
      var userNameLabel = document.createElement('a-troika-text');
      userNameLabel.setAttribute('text', {
        color: 'white',
        width: 1.5,
        wrapCount: 30, 
        value: this.data.playerName
      });
      userNameLabel.setAttribute('position', '.25 -0.2 0');
      labelPlane.appendChild(userNameLabel);

      // Company - Role
      var userCompanyRole = document.createElement('a-troika-text');
      userCompanyRole.setAttribute('text', {
        color: 'white',
        width: 1.5,
        value: `${this.data.playerCompany} - ${this.data.playerRole}`
      });
      userCompanyRole.setAttribute('position', '.25 -0.31 0');
      labelPlane.appendChild(userCompanyRole);

      // Progress Planes - Experience, Network, Learn
      // Experience
      var progressPlaneExperience = document.createElement('a-entity');
      progressPlaneExperience.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneExperience.setAttribute('material', {
        color: '#FDFC0D',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneExperience.setAttribute('position', '-0.41 -0.385 0.01');
      labelPlane.appendChild(progressPlaneExperience);
      // Network
      var progressPlaneNetwork = document.createElement('a-entity');
      progressPlaneNetwork.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneNetwork.setAttribute('material', {
        color: '#3FA9F5',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneNetwork.setAttribute('position', '-0.235 -0.385 0.01');
      labelPlane.appendChild(progressPlaneNetwork);
      // Learn
      var progressPlaneLearn = document.createElement('a-entity');
      progressPlaneLearn.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneLearn.setAttribute('material', {
        color: '#ED1E79',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneLearn.setAttribute('position', '-0.06 -0.385 0.01');
      labelPlane.appendChild(progressPlaneLearn);
      
      // Like icon
      var likeIcon = document.createElement('a-image');
      likeIcon.setAttribute('src', this.data.likeImageVideo);
      likeIcon.setAttribute('width', 0.1,);
      likeIcon.setAttribute('height', 0.1);
      likeIcon.setAttribute('position', '0.325 0.14 0.01');
      likeIcon.setAttribute('material', {
        transparent: true,
        shader: 'flat'
      });
      labelPlane.appendChild(likeIcon);
      // Like counter
      var likeCounter = document.createElement('a-troika-text');
      likeCounter.setAttribute('text', {
        color: 'white',
        width: 1.5,
        value: 22
      });
      likeCounter.setAttribute('position', '0.805 0 0.0');
      likeIcon.appendChild(likeCounter);

      // Append the created custom nametag banner to the nametag element
      videoFrameEl.appendChild(labelPlane);

      // TODO - Logic for opening the player's profile will go here
      labelPlane.setAttribute('raycaster-interaction-hotspot', { meshLabelText: 'Profile' });
      try {
        labelPlane.addEventListener('click', () => {
          const playerId =  JSON.parse(this.el.getAttribute('data-networked')).user.id;
          const nametagClickedEvent = new CustomEvent('on-nametag-clicked', {
            detail: { playerId } 
          });
          document.dispatchEvent(nametagClickedEvent);
        });
        
      } catch (error) {
        console.log('playerId err', error);
      }
    } else {
      node.material.transparent = true;
      node.material.opacity = 0;

      // Show nametag labels
      const userNameEl = this.el.querySelector('.nametag')
      userNameEl.setAttribute('visible', true);
      
      // Hide video labels
      const videoFrameEl = this.el.querySelector('.networked-video-frame');
      videoFrameEl.setAttribute('visible', false);
    }
  });
};

export default { schema, init, update: init };
