import Vue from 'vue';
import {office365} from 'calendar-link';
import { isURL } from 'validator';
import { getInternalAssetUrl } from '@/utils/files';

let eventDomainWhitelist = [];
fetch(getInternalAssetUrl('/json/event_domains.json'))
  .then((response) => response.json())
  .then((data) => {
    if (data && Array.isArray(data)) { eventDomainWhitelist = data; }
  });

export let userAccessList = [];
fetch(getInternalAssetUrl('/json/user_access.json'))
  .then((response) => response.json())
  .then((data) => {
    if (data && Array.isArray(data)) { userAccessList = data; }
  });

let eventAdminList = [];
fetch(getInternalAssetUrl('/json/event_admins.json'))
  .then((response) => response.json())
  .then((data) => {
    if (data && Array.isArray(data)) { eventAdminList = data; }
  });

let siteAdminList = [];
fetch(getInternalAssetUrl('/json/site_admins.json'))
  .then((response) => response.json())
  .then((data) => {
    if (data && Array.isArray(data)) { siteAdminList = data; }
  });

export const isEventAdmin = (user, eventSlug) => {
  if (isSiteAdmin(user)) { return true; }
  if (!eventSlug) { return false; }
  return user.emailAddress.indexOf('@davra') > -1 ||
    eventAdminList.some((ea) => ea.username.toLowerCase() === user.emailAddress.toLowerCase() && ea.event === eventSlug);
};

export const isSiteAdmin = (user) => {
  return user.emailAddress.indexOf('@davra') > -1 ||
    siteAdminList.some((sa) => sa.toLowerCase() === user.emailAddress.toLowerCase());
};

export const isBoothAdmin = (user, boothId) => {
  if (isSiteAdmin(user)) { return true; }
  const findUserFromAccessList = userAccessList.find((u) => u.username.toLowerCase().trim() === user.emailAddress.toLowerCase().trim());
  if (!findUserFromAccessList) { return false; }
  const boothItems = findUserFromAccessList.accessList.filter((item) => item.route === 'event-admin-booths-item');
  const anyItemEqualsCurrentBooth = boothItems.some((item) => item.param.value === boothId);
  return anyItemEqualsCurrentBooth;
};

export const hasRouteAccess = (user, targetRoute) => {
  const userAccessItem = userAccessList.find((ua) => {
    return ua.username.toLowerCase() === user.emailAddress.toLowerCase();
  });
  if (!userAccessItem) { return false; }
  return userAccessItem.accessList.some((al) => {
    const routeMatches = al.route === targetRoute.name;
    if (!routeMatches) { return false; }
    if (!al.param) { return true; }
    if (!targetRoute.params[al.param.key]) { return false; }
    return targetRoute.params[al.param.key] === al.param.value;
  });
};

export const hasEventAccess = (user, eventSlug) => {
  if (isSiteAdmin(user) || isEventAdmin(user, eventSlug)) { return true; }
  const eventWhitelist = eventDomainWhitelist.find((ed) => ed.event === eventSlug);
  if (!eventWhitelist) { return true; }
  return eventWhitelist.domains.some((d) => {
    return user.emailAddress.toLowerCase().indexOf(d.toLowerCase(), user.emailAddress.length - d.length) > -1;
  });
};

export const MixpanelEvents = {
  URL_OPENED: 'URL opened',
  PAGE: 'Page',
  ABOUT_SECTION_OPENED: 'About section opened',
  PROFILE_MENU_OPENED: 'Profile menu opened',
  EVENT_CARD_OPENED: 'Event card opened',
  AUDITORIUM_SEARCH_PERSON: 'Auditorium Search - Person selected',
  AUDITORIUM_SEARCH_EVENT: 'Auditorium Search - Event selected',
  EVENT_ADDED_TO_CALENDAR: 'Event added to calendar',
  LOGOUT: 'Log out',
  AUDITORIUM_FILTER_TODAY: `Auditorium Filters - "Today" selected`,
  AUDITORIUM_FILTER_PERIOD_TYPE: 'Auditorium Filters - Period type selected',
  AUDITORIUM_FILTER_MONTH_SWITCH: 'Auditorium Filters - Month switch',
  OPEN_BOOTH_THROUGH_CONF_CARD: 'Booth opened through Conference Floor popup',
  BOOTH_ENTERED: 'Booth entered from 3D room',
  OPEN_BOOTH_CARD_IN_CONFERENCE_FLOOR: 'Booth card opened in Conference Floor',
  BOOTH_NAME: 'Booth name',
  SWAG_DOWNLOADED_FROM_BOOTH: 'Swag downloaded from booth',
  EVENT_VISITED: 'Event visited',
  VIDEO_VIEWED: 'Video viewed',
  EXECUTIVE_VIDEO_PLAYED: 'Executive video viewed',
  ACTIVITIES_CLICKED: 'Activities opened',
  RESOURCES_CARD_OPENED: 'Resources card opened',
  ADDED_TO_SWAGBAG: 'Added to Swag Bag',
  PERIOD_TYPE: 'Period type',
  VISIBLE_DATE: 'Visible date',
  USER_NAME: 'User name',
  USER_EMAIL: 'User email',
  PERSON_NAME: 'Selected person name',
  PERSON_EMAIL: 'Selected person email',
  PERSON_ROLE: 'Selected person role',
  EVENT_NAME_SELECTED: 'Selected event name',
  EVENT_NAME: 'Event name',
  EVENT_TITLE: 'Event title',
  FILE_URL: 'File url',
  FILE_NAME: 'File name',
  VIDEO_NAME: 'Video name',
  URL: 'URL',
  CARD_URL: 'Card URL',
  EVENT: 'Event',
};

export const CalendarEventStates = {
  INACTIVE_PAST: 'INACTIVE_PAST',
  INACTIVE_FUTURE: 'INACTIVE_FUTURE',
  ACTIVE: 'ACTIVE',
  DEFAULT: 'DEFAULT',
};

export const CalendarFilterTypes = [
  {
    title: 'Playback',
    type: CalendarEventStates.INACTIVE_PAST,
    color: '#00095B'
  },
  {
    title: 'Live',
    type: CalendarEventStates.ACTIVE,
    color: '#FF5252'
  },
  {
    title: 'Upcoming',
    type: CalendarEventStates.INACTIVE_FUTURE,
    color: '#2196F3'
  },
];

export function handleAddToCalendar(selectedEvent, route) {
  // TODO: this needs to come from the store, not the route
  const globalEventName = 'N/A';
  const eventUrl = `${process.env.VUE_APP_SITE_URL}${globalEventName ? `/${globalEventName}` : ''}/auditorium/event/${selectedEvent.identifier}`;
  const calendarEvent = {
    title: `${selectedEvent.role}: ${selectedEvent.descriptionSecondary}`,
    description: `<a href="${ eventUrl }">${ eventUrl }</a><br>${ selectedEvent.eventDescription }`,
    start: new Date(selectedEvent.startTime),
    end: new Date(selectedEvent.endTime),
    location: eventUrl,
    url: eventUrl
  };
  window.open(office365(calendarEvent));
}

export function onVideoPlay(videoItem, userData = null, isExecutiveVideo = false, route) {
  // use localstorage to prevent events from firing every time video is played
  const videoId = `${ videoItem.id }`;
  if (!localStorage['lastWatchedVideoId'] || localStorage['lastWatchedVideoId'] !== videoId) {
    localStorage['lastWatchedVideoId'] = videoId;
    // fire the points event
    Vue.axios.post(`/api/trackable_events_log`, { type: 'WATCH_VIDEO', entityIds: [videoId] });
    // fire the mixpanel event
    if (userData === null) { return; }
    const mixpanelEventName = isExecutiveVideo ? MixpanelEvents.EXECUTIVE_VIDEO_PLAYED : MixpanelEvents.VIDEO_VIEWED;
    // TODO: this needs to come from the store, not the route
    const globalEventName = 'N/A';
    Vue.prototype._mixpanel.track(mixpanelEventName, {
      [MixpanelEvents.VIDEO_NAME]: videoItem.name,
      [MixpanelEvents.FILE_URL]: videoItem.srcList[0].src,
      [MixpanelEvents.USER_NAME]: userData && userData.displayName,
      [MixpanelEvents.USER_EMAIL]: userData && userData.emails && userData.emails[0],
      [MixpanelEvents.EVENT]: globalEventName
    });
  }
}

export const urlRule = (value) => {
  if (isURL(value)) { return true; }
  return `Must be a valid url`;
};

export const urlRules = [urlRule];

export const boothConstants = {
  title: {
    property: 'title',
    min: 3,
    max: 40,
    rules: [
      (v) => !!v || 'Name is required', (v) => v.length <= title.max || `Name must be ${title.max} characters or less`,
    ],
  },
  description: {
    property: 'description',
    min: 10,
    max: 1000,
    rules: [
      (v) => !!v || 'Description is required', (v) => v.length <= description.max || `Description must be ${description.max} characters or less`,
    ],
  },
  openingHours: {
    property: 'openingHours',
    min: 10,
    max: 400,
    rules: [ 
      (v) => !!v || 'Opening hours is required', (v) => v.length <= openingHours.max || `Opening hours must be ${openingHours.max} characters or less`,
    ],
  },
  youtube: {
    property: 'youtube', min: 0, max: 3000, 
    rules: [
      value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www\.youtube\.com|youtu\.be)\/.+$/.test(value)) || `Must be a valid Youtube url`
    ],
  },
  linkedin: {
    property: 'linkedin', min: 0, max: 3000,
    rules: [
      value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www\.linkedin\.com|linkedin\.be)\/.+$/.test(value)) || `Must be a valid Linkedin url`
    ],
  },
  twitter: {
    property: 'twitter', min: 0, max: 3000, 
    rules: [
      value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(value)) || `Must be a valid Twitter url`
    ],
  },
  facebook: {
    property: 'facebook', min: 0, max: 3000, 
    rules: [
      value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/.test(value)) || `Must be a valid Facebook url`
    ],
  },
  website: {
    property: 'website', min: 0, max: 3000,
  },
  
};

export const { title, description, openingHours, youtube, linkedin, twitter, facebook, website } = boothConstants;

export const boothForm = {

  generalFields: ['name', 'description', 'adminName', 'adminEmail'],
  generalForm: [
    {
      label: 'Enter exhibit name',
      property: title.property,
      component: 'InputAndLabel',
      min: title.min, 
      max: title.max,
      rules: title.rules,
      classes: 'px-4 pb-6',
      counter: title.max,
      hint: '* Required'
      
    },
    {
      label: 'Enter exhibit description',
      property: description.property,
      component: 'TextAreaAndLabel',
      min: description.min, 
      max: description.max,      
      rules: description.rules,
      classes: 'px-4 pb-6',
      counter: description.max,
      hint: '* Required'
    },
    {
      label: 'Enter the hours staff will be present at your exhibit',
      property: openingHours.property,
      title: 'Opening Hours',
      subtitle: 'When will the exhibit team staff the exhibit',
      component: 'TextAreaAndLabel',
      min: openingHours.min, 
      max: openingHours.max,
      rules: openingHours.rules,
      classes: 'px-4 pb-6',
      counter: openingHours.max,
      hint: '* Required'
    },
    { 
      label: 'Youtube',
      property: 'youtube',
      title: 'Social Media Links',
      subtitle: 'Your social media links will be visible in the event. Leave fields blank if you do not want to include links.',
      component: 'InputAndLabel',
      min: youtube.min,  
      max: youtube.max,
      rules: youtube.rules,
      classes: 'px-4 pr-16 mr-16 pb-6',
      counter: 0,
      hint: 'Optional'
    },
    {
      label: 'Linkedin',
      property: 'linkedin',
      component: 'InputAndLabel',
      min: linkedin.min,  
      max: linkedin.max,
      rules: linkedin.rules,
      classes: 'px-4 pr-16 mr-16  pb-6',
      counter: 0,
      hint: 'Optional'
    },
    {
      label: 'Twitter',
      property: 'twitter',
      component: 'InputAndLabel',
      min: twitter.min,  
      max: twitter.max,
      rules: twitter.rules,
      classes: 'px-4 pr-16 mr-16  pb-6',
      counter: 0,
      hint: 'Optional'
    },
    {
      label: 'Facebook',
      property: 'facebook',
      component: 'InputAndLabel',
      min: facebook.min,  
      max: facebook.max,
      rules: facebook.rules,
      classes: 'px-4 pr-16 mr-16  pb-6',
      counter: 0,
      hint: 'Optional'
    },
    {
      label: 'Website',
      property: 'website',
      component: 'InputAndLabel',
      min: website.min,  
      max: website.max,
      classes: 'px-4 pr-16 mr-16  pb-6',
      counter: 0,
      hint: 'Optional'
    },
    // {
    //   label: "Admin Name",
    //   property: "adminName",
    //   component: "InputAndLabel",
    //   min: 10, 
    //   max: 300,
    //   rules: [ value => !!value || 'Required.', value => (value && value.length >= 10) || `Min ${10} characters`],
    // },
    // {
    //   label: "Admin Email",
    //   property: "adminEmail",
    //   component: "InputAndLabel",
    //   min: 10, 
    //   max: 70,
    //   rules: [ value => !!value || 'Required.', value => (value && isEmail(value)) || 'Not a valid email']
    // },
  ],
  teamFields: ['team'],
  teamMemberForm: [
    {
      label: 'Name',
      property: 'name',
      component: 'InputAndLabel',
      min: 3, 
      max: 100,
      rules: [],
    },
    {
      label: 'Company',
      property: 'company',
      component: 'InputAndLabel',
      disabled: true,
      min: 3, 
      max: 100,
      rules: [],
    },
    {
      label: 'Role',
      property: 'role',
      component: 'InputAndLabel',
      min: 3, 
      max: 100,
      rules: [],
    },
    {
      label: 'Email',
      property: 'email',
      component: 'InputAndLabel',
      min: 10, 
      max: 300,
      rules: []
      
    },
    {
      label: 'Bio',
      property: 'description',
      component: 'TextAreaAndLabel',
      min: 10, 
      max: 900,
      rules: [],
    },
  ]
};
