// import Vue from "vue";
import { GET_NOTIFICATIONS_EVENTS, GET_NOTIFICATIONS, SET_NOTIFICATIONS_EVENTS, SET_NOTIFICATIONS_EVENT } from './types.js';
import moment from 'moment';
export default {
  state: () => ({
    notifications_events: [],
  }),
  mutations: {
    [SET_NOTIFICATIONS_EVENTS](state, value) {
      // console.log("setNotificationsEvents", state, value);
      state.notifications_events = value;
    },
    [SET_NOTIFICATIONS_EVENT](state, value) {
      // console.log("setNotificationsEvents", state, value);
      const updateValue = {...value, time: moment(value.createdTime).fromNow(), read: false };
      state.notifications_events.unshift(updateValue);
    },
  },
  actions: {
    [GET_NOTIFICATIONS]({ commit }, data) {
      commit(SET_NOTIFICATIONS_EVENTS, []);
      // return Vue.axios.get(`/api/notifications`).then((data) => {

      // });
    },
    [GET_NOTIFICATIONS_EVENTS]({ commit }) {
      commit(SET_NOTIFICATIONS_EVENTS, []);
      // return Vue.axios.get(`/api/notifications/event`).then((data) => {
      //   let notifications_events = data && [...data.data].sort((a,b) => {
      //      return a.createdTime > b.createdTime ? -1 : 1;
      //   }).map((item) => ({...item, time: moment(item.createdTime).fromNow(), read: true }));
      //   if (notifications_events) {
      //       commit(SET_NOTIFICATIONS_EVENTS, notifications_events);
      //   } else {
      //     commit(SET_NOTIFICATIONS_EVENTS, []);
      //     return null;
      //   }
      // });
    }
  },
};
