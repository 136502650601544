import AFRAME from 'aframe';

const schema = {
  meshLabelText: { type: 'string', default: '' },
  meshColorDefault: { type: 'color', default: '#FFFFFF' },
  meshColorHover: { type: 'color', default: '#e6e6e6' },
  cursorColorDefault: { type: 'color', default: '#B5B5B5' },
  cursorColorHover: { type: 'color', default: '#FFFFFF' },
  cursor: { type: 'string', default: 'player-cursor-entity' },
  playerLabelColorDefault: { type: 'color', default: '#000000' },
};

const init = function () {
  let activeObject = null;
  this.el.classList.add('raycastable');
  this.el.addEventListener('mouseenter', (e) => {
    if (!e.detail.intersection || !e.detail.intersection.object) { return; }
    const intersectObject = e.detail.intersection.object;
    this.setCursorColor(this.data.cursorColorHover);
    this.setMeshColor(intersectObject, this.data.meshColorHover);
    if (this.data.meshLabelText) { this.appendActionSprite(this.el, intersectObject, this.data.meshLabelText); }
    activeObject = intersectObject;
  });
  this.el.addEventListener('mouseleave', (e) => {
    
    this.setCursorColor(this.data.cursorColorDefault);
    this.setMeshColor(activeObject, this.data.meshColorDefault);
    this.removeActionSprite(this.el);
    if (this.el.className === 'plane-nametag raycastable') {
      this.setMeshColor(activeObject, this.data.playerLabelColorDefault);
    }
    activeObject = null;
  });
};

const setCursorColor = function (color) {
  const cursorEl = document.getElementById(this.data.cursor);
  if (!cursorEl) { return; }
  cursorEl.setAttribute('material', 'color', color);
};

const setMeshColor = function (object, color) {
  const mesh = object.getObjectByProperty('type', 'Mesh');
  if (!mesh) { return; }
  mesh.material.color.setHex(color.replace('#', '0x')).convertSRGBToLinear();
};

const appendActionSprite = function (el, mesh, text) {
  const spriteEl = document.createElement('a-entity');
  spriteEl.className = 'action-text-sprite';
  spriteEl.setAttribute('text-sprite', {
    text,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    backgroundColor: '#000000',
    fontSize: 0.12,
    opacity: 0.8
  });
  el.appendChild(spriteEl);
  setTimeout(() => {
    const meshGeometryPosition = this.calculateGeometryPosition(mesh);
    meshGeometryPosition.x = meshGeometryPosition.x + 0.1;
    meshGeometryPosition.z = meshGeometryPosition.z + 0.1;
    spriteEl.object3D.position.copy(meshGeometryPosition);
    spriteEl.object3D.children.forEach((child) => { child.material.depthTest = false; });
  });
};

const removeActionSprite = function (el) {
  const spriteEls = el.getElementsByClassName('action-text-sprite');
  spriteEls.forEach((spriteEl) => {
    el.removeChild(spriteEl);
  });
};

const calculateGeometryPosition = function (mesh) {
  const vec = new AFRAME.THREE.Vector3();
  vec.fromBufferAttribute(mesh.geometry.attributes.position, 1);
  vec.applyMatrix4(mesh.matrix);
  return vec;
};

export default { schema, init, setCursorColor, setMeshColor, appendActionSprite, removeActionSprite, calculateGeometryPosition };
