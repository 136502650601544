import Vue from 'vue';
import AFRAME from 'aframe';
import 'aframe-orbit-controls';
import 'aframe-troika-text';
import 'aframe-text-sprite';
import './aframe/aframe-extras.js';
import 'aframe-physics-system';
import './aframe/index.js';
import 'networked-aframe';
import './aframe/naf-janus-adapter.min.js';
import './aframe/html-mesh.js';
import 'aframe-look-at-component';
// eslint-disable-next-line no-undef
Vue.prototype.$naf = NAF;

Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-sphere',
  'a-cylinder',
  'a-plane',
  'a-assets',
  'a-asset-item',
  'a-cursor',
  'a-cubemap',
  'a-light',
  'a-troika-text'
];

export { AFRAME };
