import Vue from 'vue';
window.Vue = Vue;
import App from './App.vue';
import router from './router';
Vue.router = router;
import 'webrtc-adapter';
import './plugins/aframe';
import './plugins/vuex';
import './plugins/vue-2-breadcrumbs';
import './plugins/vue-plyr';
import './plugins/donut-chart';
import './plugins/mixpanel';
import './plugins/uploadcare';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import DatetimePicker from 'vuetify-datetime-picker';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';


Vue.use(VueAxios, axios);
Vue.use(DatetimePicker);
Vue.use(CoolLightBox);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
