import Vue from 'vue';
import {
  ADD_TO_CHAT_STREAM, SET_NETWORK_CONNECTION_STATUS, ADD_TO_PARTICIPANT_LIST, RESET_PARTICIPANT_LIST, REMOVE_FROM_PARTICIPANT_LIST, IS_RECONNECT_MAXED, RESET_RECONNECT_ATTEMPT_COUNT, INCREMENT_RECONNECT_ATTEMPT_COUNT, CLEAR_RECONNECT_HANDLER_INTERVAL, SET_SEEN_CHAT_MESSAGE_COUNT, GET_UNREAD_CHAT_MESSAGE_COUNT, GET_PARTCIPANT_BY_ID, INIT_NETWORKING, INIT_CHAT_ALL, SEND_CHAT_ALL, INIT_PARTICIPANT_LIST, TRIGGER_NETWORKING_EVENT, ADD_NETWORKING_EVENT_LISTENER
} from './types.js';
import { TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE, TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE } from '@/store/game-settings/types.js';
import { NETWORK_CONNECTION_STATUS, cleanupLocalMedia } from '@/utils/networking';

const NETWORK_RETRY_INTERVAL_MS = 10000;
const MAX_NETWORK_RETRY_COUNT = 3;

export default {
  state: () => ({
    participantList: [],
    chatStreamData: [],
    selfDisplayName: '',
    selfDisplayNameSuffix: '(You)',
    selfClientId: '',
    seenChatMessageCount: 0,
    chatroomId: null,
    nafConnection: null,
    eventBus: new Vue(),
    eventController: new AbortController(),
    retryConnectionHandler: null,
    retryConnectionAttemptCount: 0,
    networkConnectionStatus: 0
  }),
  mutations: {
    [ADD_TO_CHAT_STREAM](state, value) {
      state.chatStreamData.push(value);
    },
    [ADD_TO_PARTICIPANT_LIST](state, value) {
      state.participantList.push(value);
    },
    [RESET_PARTICIPANT_LIST](state) {
      state.participantList = [];
    },
    [REMOVE_FROM_PARTICIPANT_LIST](state, clientId) {
      state.participantList = state.participantList.filter((p) => {
        return p.clientId !== clientId;
      });
    },
    [SET_SEEN_CHAT_MESSAGE_COUNT](state) {
      state.seenChatMessageCount = state.chatStreamData.length;
    },
    [SET_NETWORK_CONNECTION_STATUS](state, value) {
      state.networkConnectionStatus = value;
    },
    [RESET_RECONNECT_ATTEMPT_COUNT](state) {
      state.retryConnectionAttemptCount = 0;
    },
    [INCREMENT_RECONNECT_ATTEMPT_COUNT](state) {
      state.retryConnectionAttemptCount++;
    },
    [CLEAR_RECONNECT_HANDLER_INTERVAL](state) {
      clearInterval(state.retryConnectionHandler);
    }
  },
  getters: {
    [GET_UNREAD_CHAT_MESSAGE_COUNT](state) {
      return state.chatStreamData.length - state.seenChatMessageCount;
    },
    [GET_PARTCIPANT_BY_ID]: (state) => (clientId) => {
      return state.participantList.find((p) => p.clientId === clientId);
    },
    [IS_RECONNECT_MAXED]: (state) => {
      return state.retryConnectionAttemptCount >= MAX_NETWORK_RETRY_COUNT;
    }
  },
  actions: {
    [INIT_NETWORKING]({ commit, dispatch }, { roomId, userData, nafInstance }) {
      // first, prompt for device access (no need to attach devices, permission is needed for networking to work well)
      // https://davranetworks.atlassian.net/browse/SR-1730
      navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
        // permission granted, continue with networking setup
        commit(SET_NETWORK_CONNECTION_STATUS, NETWORK_CONNECTION_STATUS.CONNECTING);
        const networkedSceneOptions = {
          debug: true,
          adapter: 'janus',
          serverURL: process.env.VUE_APP_SIG_URL,
          room: roomId,
          onConnect: 'on-aframe-networked-connect'
        };
        dispatch(INIT_PARTICIPANT_LIST, { retryConnectionFn: () => {
          // TODO: emit retry event
          commit(SET_NETWORK_CONNECTION_STATUS, NETWORK_CONNECTION_STATUS.CONNECTING);
          cleanupLocalMedia();
          commit(TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE, true);
          commit(TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE, true);
          dispatch(TRIGGER_NETWORKING_EVENT, { name: 'on-network-reconnect' });
          dispatch('buildNetworkingDom', { networkedSceneOptions, nafInstance, userData, isRetryInstance: true });
        }});
        dispatch(INIT_CHAT_ALL, { displayName: userData.displayName, clientId: userData.id, chatroomId: roomId, nafInstance });
        window['on-aframe-networked-connect'] = () => {
          // TODO: emit connected event
          commit(SET_NETWORK_CONNECTION_STATUS, NETWORK_CONNECTION_STATUS.CONNECTED);
        };
        dispatch('buildNetworkingDom', { networkedSceneOptions, nafInstance, userData, isRetryInstance: false });
      }).catch((e) => {
        // device permission was denied, in which case networking should not be enabled
      });
    },
    [INIT_CHAT_ALL]({ commit, state, getters }, { displayName, clientId, chatroomId, nafInstance }) {
      if (!nafInstance) { return console.error('sr-networking', 'Chat could not be initialiased, no NAF instance'); }
      state.chatroomId = chatroomId;
      state.selfDisplayName = displayName;
      state.selfClientId = clientId;
      state.nafConnection = nafInstance.connection;
      state.nafConnection.subscribeToDataChannel(`chat-${chatroomId}`, (_clientId, _dataType, chatItem) => {
        if (!chatItem.senderId) { return console.error('sr-networking', 'Chat received from unknown source', chatItem); }
        if (!chatItem.message) { return console.error('sr-networking', 'Chat received with no message data', chatItem); }
        const participant = getters[GET_PARTCIPANT_BY_ID](chatItem.senderId);
        if (!participant) { return console.error('sr-networking', 'Chat received from unknown source', chatItem.senderId); }
        const chatItemData = { timestamp: Date.now(), username: participant.user.displayName, message: chatItem.message };
        commit(ADD_TO_CHAT_STREAM, chatItemData);
      });
    },
    [SEND_CHAT_ALL]({ commit, state }, { message }) {
      if (!state.chatroomId) { console.error('sr-networking', 'Chat could not be sent, no chatroomId'); return false; }
      if (!state.nafConnection) { console.error('sr-networking', 'Chat could not be sent, no NAF instance'); return false; }
      if (message.length === 0) { return false; }
      state.nafConnection.broadcastDataGuaranteed(`chat-${state.chatroomId}`, { message, senderId: state.nafConnection.adapter.clientId });
      commit(ADD_TO_CHAT_STREAM, { timestamp: Date.now(), username: `${state.selfDisplayName} ${state.selfDisplayNameSuffix}`, message, isSelf: true });
      commit(SET_SEEN_CHAT_MESSAGE_COUNT);
      return true;
    },
    [INIT_PARTICIPANT_LIST]({ commit, state }, options) {
      const setupParticipantList = () => {
        state.eventController.abort();
        state.eventController = new AbortController();
        commit(RESET_PARTICIPANT_LIST);
  
        document.body.addEventListener('entityCreated', (e) => {
          const networkedComponent = e.detail.el.getAttribute('networked');
          const remoteTemplateEl = e.detail.el;
          try {
            const participantData = JSON.parse(remoteTemplateEl.getAttribute('data-networked'));
            if (!participantData.user) { commit(ADD_TO_PARTICIPANT_LIST, { clientId: networkedComponent.creator }); }
            else { commit(ADD_TO_PARTICIPANT_LIST, Object.assign(participantData, { clientId: networkedComponent.creator })); }
          } catch (_) {
            commit(ADD_TO_PARTICIPANT_LIST, { clientId: networkedComponent.creator });
          }
        }, { signal: state.eventController.signal });
    
        document.body.addEventListener('clientDisconnected', (e) => {
          commit(REMOVE_FROM_PARTICIPANT_LIST, e.detail.clientId);
        }, { signal: state.eventController.signal });
      };
      
      setupParticipantList();
      commit(RESET_RECONNECT_ATTEMPT_COUNT);
      commit(CLEAR_RECONNECT_HANDLER_INTERVAL);
      state.retryConnectionHandler = setInterval(() => {
        // TODO: handle existing local media streams gracefully
        if (state.participantList.length > 1) { return clearInterval(state.retryConnectionHandler); }
        if (options && options.retryConnectionFn) {
          console.warn('sr-networking', 'Invoking connection retry function');
          setupParticipantList();
          options.retryConnectionFn();
          commit(INCREMENT_RECONNECT_ATTEMPT_COUNT);
          if (state.retryConnectionAttemptCount >= MAX_NETWORK_RETRY_COUNT) {
            return clearInterval(state.retryConnectionHandler);
          }
        }
      }, NETWORK_RETRY_INTERVAL_MS);

      // TODO: consolidate participant list with this.$naf.connection.getConnectedClients() every 30s
      // setInterval(() => {
      //   // console.log('this.$naf.connection.isConnected()', this.$naf.connection.isConnected());
      //   // console.log('this.$naf.connection.getConnectedClients()', this.$naf.connection.getConnectedClients());
      //   // console.log('connectedParticipants', connectedParticipants);
      // }, 5000);
    },
    [TRIGGER_NETWORKING_EVENT]({ state }, { name, data }) {
      state.eventBus.$emit(name, data);
    },
    [ADD_NETWORKING_EVENT_LISTENER]({ state }, { name, listener }) {
      state.eventBus.$off(name);
      state.eventBus.$on(name, listener);
    }
  }
};
